<template>
    <b-modal
      id="orientation-modal"
      hide-header
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
    >
    <div class="modal-header">
      <div class="modal-title">
        Criar modelo de orientação personalizado
      </div>
      <span class="icon-box">
        <v-close class="icon stroke" @click="closeModal()"/>
      </span>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label for="nome_orientacao" class="d-flex inline justify-content-between">
              Título da orientação

              <b-form-checkbox
                :checked="Boolean(professional)"
                @change="professional = !professional"
              >
                <span class="d-flex inline">
                  Orientação privada
                  <span id="help-icon" class="icon-box">
                    <HelpCircle class="icon stroke" />
                  </span>
                  <b-tooltip target="help-icon" placement="bottom">
                    Ao selecionar o checkbox a orientação deverá ser privada, ou seja, somente o profissional criador deve ter acesso.
                  </b-tooltip>
                </span>
              </b-form-checkbox>
            </label>
            <input
              id="nome_orientacao"
              autocomplete="off"
              type="text"
              placeholder="Descrever"
              class="form-control"
              v-model="form.name"
            />
            <div
              v-if="validated  && !form.name"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <label for="descricao_orientacao">Texto da orientação</label>
            <VueEditor
              id="descricao"
              class="text-area"
              v-model="form.template"
              placeholder="Descrever "
              :editorToolbar="customToolbar"
            />
            <div
              v-if="validated  && !form.template"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
        </b-form-group>
      </b-col>
    </b-row>

      <div class="wrapper-button">
        <div class="d-flex">
          <b-button
            v-if="form.id"
            variant="outline-primary"
            class="mr-2"
            size="lg"
            @click="
              defaultType = closeModal()"
          >
            Cancelar
          </b-button>
          
          <b-button
            v-if="form.id"
            variant="primary"
            size="lg"
            @click="
              defaultType = updateOrientation()"
          >
            Salvar alterações
          </b-button>
          <b-button
            v-else
            variant="primary"
            size="lg"
            @click="createOrientation()"
          >
            Adicionar
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

  <script>
  import { VueEditor } from "vue2-editor"
  import Close from '@/assets/icons/close.svg'
import { getCurrentClinic, getCurrentUser } from '../../utils/localStorageManager'

  export default {
    props: {
      orientation: Object,
      useProfessional: Boolean,
    },
    components: {
      VueEditor,
      HelpCircle: () => import('@/assets/icons/help-circle.svg'),
      'v-close': Close,
    },
    data() {
      return {
        clinic: getCurrentClinic(),
        user: getCurrentUser(),
        professional: false,
        form: {},
        validated: false,
        fields: this.getDefaultFields(),
        isLoading: false,
        customToolbar: [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold","underline","strike"], // toggled buttons
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" }
          ],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ],
      }
    },
    computed: {
      isMenuAdmin: function() {
        return this.$route.path.includes('admin')
      }
    },
    methods: {
      getDefaultFields() {
        return {
          name: null,
          template: null,
        }
      },
      isValidForm() {
        this.validated = true
        return this.form.name && this.form.template
        },
      clearForm() {
        this.form = this.getDefaultForm();
        this.professional = false
        this.$bvModal.hide('orientation-modal')
      },
      closeModal(){
        this.$emit('clearOrientantions')
        this.$bvModal.hide('orientation-modal')
      },
      getDefaultForm() {     
        return {
          name: null,
          template: null,
        }
      },
      async createOrientation() {
        if (!this.isValidForm()) return
        const loading = this.$loading.show()
        try {
            const response = await this.api.createPostOperativeGuidelines({
              name: this.form.name,
              template: this.form.template,
              clinic_id: !this.isMenuAdmin ? this.clinic.id : null,
              professional_id: !this.isMenuAdmin ? this.user.id : null,
              private: !this.isMenuAdmin ? this.professional : false
            })
            this.clearForm();
            loading.hide();
            this.$toast.success('Orientação cadastrada com sucesso!')
            this.$bvModal.hide('orientation-modal')
            this.$emit('orientacaoCreated', response.data)
        } catch (error) {
            loading.hide();
            this.$toast.error(error.message)
        } finally {
            loading.hide()
        }
      },
      async updateOrientation() { 
        if (!this.isValidForm()) return
        const props = {
          name: this.form.name,
          template: this.form.template,
          clinic_id: !this.isMenuAdmin ? this.clinic.id : null,
          professional_id: !this.isMenuAdmin ? this.user.id : null,
          private: !this.isMenuAdmin ? this.professional : false        
        }
        const loading = this.$loading.show()
        try {
            const response = await this.api.updatePostOperativeGuidelines(this.form.id, props)

              this.$toast.success('Laudo atualizado com sucesso!')
              this.$bvModal.hide('orientation-modal')
              loading.hide();
              this.$emit('orientacaoUpdated', response.data)
          } catch (error) {
              this.$toast.error(error.message)
          } finally {
              loading.hide()
          }
      },
    },
    watch: {
      orientation(newValue) {
      if (newValue) {
        this.form = {...newValue}
        if(newValue.private) this.professional = true
      }
    }
    }
  }
  </script>
  <style lang="scss">
#orientation-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
    textarea {
      min-height: 200px;
      max-height: 1000px;
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
  
  .icon {
    height: 24px;
    width: 24px;
    margin-left: 5px;
    stroke: var(--neutral-500);
  }

  @media (min-width: 1440px) {
    .icon {
      margin-left: 5px;
    }
  }
}
</style>
  