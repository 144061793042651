<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <b-row>
            <b-col cols="12">
              <div class="form-group">
                <validation-provider
                  name="orientacao"
                  :rules="{ required: true }"
                  v-slot="{ errors, touched }"
                >
                  <multiselect
                    v-if="!fields.custom"
                    v-model="fields.orientacao"
                    :options="options"
                    :option-height="40"
                    :showLabels="false"
                    :searchable="true"
                    placeholder="Pesquisar por orientação"
                    class="search-mode with-border"
                    @select="select"
                    :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                  >
                    <template slot="caret">
                      <div class="search">
                        <Search />
                      </div>
                    </template>
                    <template slot="noOptions">
                      Digite para pesquisar uma orientação
                    </template>
                    <template slot="noResult" slot-scope="props">
                      <li @click="setCustom(true, props.search)">
                        <div class="multiselect__option custom-item">
                          <Plus class="icon" />
                          Adicionar orientação pós operatória personalizada "{{
                            props.search
                          }}"
                        </div>
                      </li>
                    </template>
                  </multiselect>
                  <div class="custom-input" v-else>
                    <b-button variant="link" @click="setCustom(false)">
                      Selecionar um item da lista
                    </b-button>
                    <input
                      autocomplete="off"
                      v-model="fields.orientacao"
                      :readonly="!canEdit"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                    />
                  </div>
                  <div class="new-medical-report">
                  </div>
                  <div 
                    v-if="!fields.custom"
                    class="new-orientation" 
                  >
                    <v-icon 
                      v-if="fields.orientacao"
                      v-b-tooltip.hover title="Limpar seleção" 
                    >
                      <Close class="icon close" @click="() => {fields = getDefaultFields()}" />
                    </v-icon>

                    <v-icon 
                      v-if="!fields.orientacao"
                      v-b-tooltip.hover title="Criar modelo de orientação personalizado"   
                    >
                      <Plus class="icon" @click="$bvModal.show('orientation-modal')" />
                    </v-icon>

                    <v-icon 
                      v-if="fields.orientacao && isCreatedOrientation(fields.orientacao)"
                      v-b-tooltip.hover title="Editar" 
                    >
                      <Edit class="icon edit" @click="editOrientation(fields)"/>
                    </v-icon>

                    <v-icon 
                      v-if="fields.orientacao && isCreatedOrientation(fields.orientacao)"
                      v-b-tooltip.hover title="Deletar"
                    >
                      <Trash class="icon trash" @click="confirmDelete(fields)" />
                    </v-icon>
                  </div>
                </validation-provider>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div class="form-group">
                <validation-provider
                  name="descricao"
                  :rules="{ required: true }"
                >
                  <VueEditor
                    id="descricao"
                    class="vue_edition"
                    v-model="fields.descricao"
                    :editorToolbar="customToolbar"
                  />
                </validation-provider>
              </div>
            </b-col>
          </b-row>
          <b-row class="justify-content-end">
            <b-col cols="2" v-if="editingIndex !== null">
              <b-button
                variant="danger"
                block
                class="mt-27 h-56"
                :disabled="invalid"
                @click="cancelEdition"
              >
                Cancelar edição
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="primary"
                block
                class="mt-27 h-56"
                :disabled="invalid"
                @click="addItem"
              >
                {{ editingIndex !== null ? 'Atualizar' : 'Adicionar' }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>


        <b-row v-if="form.value.length">
          <b-col>
            <h5><b>Orientações adicionadas</b></h5>
            <hr class="custom-hr">
            <div v-for="(value, index) of form.value" :key="index" class="orientation-item">
              <div class="name-row">
                <span class="orientation-name"><b>Nome:</b> {{ value.orientacao }}
                </span>
                <div class="icons-container">
                  <Icon tooltip="Editar">
                    <Edit class="icon edit" @click="editItem(value, index)"/>
                  </Icon>
                  <Icon tooltip="Deletar">
                    <Trash class="icon trash" @click="removeItem(index)" />
                  </Icon>
                </div>
              </div>
              <div class="orientation-description"><b>Descrição da orientação: </b><div v-html="value.descricao"></div></div>
              <hr v-if="index !== form.value.length - 1" class="custom-hr">
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <OrientationModal   
      @orientacaoCreated="addOrientationOption"
      @orientacaoUpdated="editOrientationOption"
      :orientation="selectedOrientation"
      @clearOrientations="selectedOrientation = null"
    />
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Search from '@/assets/icons/search.svg'
import Plus from '@/assets/icons/plus.svg'
import { VueEditor } from "vue2-editor"
import OrientationModal from '../../../Orientation/OrientationModal.vue';
import { getCurrentClinic, getCurrentUser } from '../../../../utils/localStorageManager';

export default {
  components: {
    FormWrapper,
    Search,
    Plus,
    VueEditor,
    Close: () => import('@/assets/icons/close.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Icon: () => import('@/components/General/Icon'),
    OrientationModal
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.orientacaoPosOperatoria,
    })
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      editingIndex: null,
      options: [],
      postOperativeGuidelines: [],
      fields: this.getDefaultFields(),
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ],
      selectedOrientation: null
    }
  },
  async mounted() {
    await this.getPostOperativeGuidelines()
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/orientacaoPosOperatoria', ['handleFields', 'handleProps']),
    getDefaultFields() {
      return {
        custom: false,
        orientacao: null,
        descricao: null,
      }
    },
    onChange() {
      // this.attendanceReview.posOperatorio = this.form.value.length > 0
    },
    async getPostOperativeGuidelines() {
      try {
        const response = await this.api.getPostOperativeGuidelines({
          clinic_id: this.clinic.id,
          professional_id: this.user.id
        })
        this.postOperativeGuidelines = response.data
        this.options = response.data.map(data => {
          return data.name
        })
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    select(query) {
      this.postOperativeGuidelines.find(value => {
        if (value.name === query) this.fields.descricao = value.template
      })
    },
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.descricao = null
      this.fields.orientacao = value ? searchTerm : null
    },
    addItem() {
      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    cancelEdition() {
      this.editingIndex = null
      this.fields = this.getDefaultFields()
    },
    editOrientation(orientation){
      this.selectedOrientation = this.postOperativeGuidelines.find(value => {
        return value.name === orientation.orientacao
      })
      this.$bvModal.show('orientation-modal')
    },
    isCreatedOrientation(name) {
      const op = this.postOperativeGuidelines.find(value => {
        return value.name === name
      })

      return op.clinic_id && op.professional_id === this.user.id
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    },
    addOrientationOption(data){
      this.postOperativeGuidelines.push(data)
      this.options.push(data.name)
      this.options = this.options.sort()
      this.fields.orientacao = data.name
      this.fields.descricao = data.template
    },
    editOrientationOption(data){
      const oldOrientation = this.selectedOrientation
      const postOperativeIndex = this.postOperativeGuidelines.findIndex(op => op.name === oldOrientation.name)
      const optIndex = this.options.findIndex(op => op === oldOrientation.name)

      this.postOperativeGuidelines.splice(postOperativeIndex, 1)
      this.options.splice(optIndex, 1)
      this.postOperativeGuidelines.push(data)
      this.options.push(data.name)
      this.options = this.options.sort()
      this.fields.orientacao = data.name
      this.fields.descricao = data.template
    },
    async confirmDelete(orientation) {

      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
            Você tem certeza que deseja excluir o modelo de orientação  
            <span>${orientation.orientacao}</span>
            <p>Não será possível desfazer essa ação.</p>
            </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async res => {
        if (res.isConfirmed) {
          await this.deleteOrientation(orientation)
        }
      })
    },
    async deleteOrientation(orientation) {
      try {
        const postOperative = this.postOperativeGuidelines.find(op => op.name === orientation.orientacao)
        await this.api.deletePostOperativeGuidelines(postOperative.id)
        const postOperativeIndex = this.postOperativeGuidelines.findIndex(op => op.name === postOperative.name)
        const optIndex = this.options.findIndex(op => op === postOperative.name)
        this.postOperativeGuidelines.splice(postOperativeIndex, 1)
        this.options.splice(optIndex, 1)
        this.fields = this.getDefaultFields()
        this.$toast.success('Modelo de orientação excluída com sucesso')
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  .custom-item {
    color: var(--blue-500);
    display: flex;
    align-items: center;
    font-weight: 600;
    .icon {
      width: 24px;
      height: 24px;
      fill: var(--blue-500);
      margin-right: 12px;
    }
  }
  .multiselect__single {
    @media(min-height: 1600){
      font-size: 1.6vh;
    }
    @media(max-height: 1600){
      font-size: 1vw;
    }
  }
}

.new-orientation {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  top: 0;
  right: 10px;
  z-index: 10;
  gap: 10px;
  margin-right: 10px;

  .icon {
    width: 20px;
    height: 20px;
    fill: var(--blue-500);
    cursor: pointer;

    &.trash {
      fill: none;
      stroke: var(--states-red-soft);
    }

    &.edit {
      fill: none;
      stroke: var(--blue-500);
    }

    &.close {
      fill: var(--type-active);
    }
  }
}

.button {
  height: 38px;
  margin-bottom: 24px;
}
.vue_edition {
    background-color: white;

    :deep(.ql-editor) {
      min-height: 100px !important;
    }
  }

  .orientation-item{
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    line-height: 2;
    
    .name-row{
      display: flex;
      justify-content: space-between;
    }
    .orientation-name{
      b {
        font-size: medium;
      }
    }
  
    .orientation-description{
      b {
        font-size: medium;
      }
    }
  
    .icons-container{
      display: flex;
      .icon {
       width: 25px;
       height: 25px;
       margin: 0px 5px;
       fill: var(--blue-500);
       cursor: pointer;
  
       &.trash {
         fill: none;
         stroke: var(--danger);
       }
  
       &.edit {
         fill: none;
         stroke: var(--blue-500);
       }
     }
    }
  }

  .custom-hr {
    height: 1px;
    background-color: lightgray;
    border: none;
    margin: 3px 0;
  }
</style>
